<template>
  <section>
    <b-row>
      <b-col>
        <b-card class="mb-2">
          <div v-if="!infoBot">
            <h4>Aqui puedes enlazar tu cuenta de WhatsApp con el bot.</h4>
            <b-col class="d-flex justify-content-center mt-4">
              <b-card border-variant="primary" class="text-center">
                <p>
                  Escanea el código QR para enlazar tu cuenta de WhatsApp con el
                  bot.
                </p>
                <img :src="urlImageQr" alt="QR" width="300" height="300" />
              </b-card>
            </b-col>
          </div>

          <b-col v-if="infoBot" class="d-flex justify-content-center mt-4">
            <b-card border-variant="success">
              <p>Tu sesión de WhatsApp está activa.</p>
              <b-col v-if="infoBot.name">
                <b>Nombre: </b>
                <span>{{ infoBot.name }}</span>
              </b-col>
              <b-col v-if="infoBot.phone">
                <b>Número: </b>
                <span>+{{ infoBot.phone }}</span>
              </b-col>
            </b-card>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-card class="shadow-sm border-0">
          <h5 class="text-primary mb-2">
            <feather-icon icon="MessageSquareIcon" size="35" class="mr-50" />
            Envío de Mensajes
          </h5>

          <b-row>
            <b-col md="6" class="mb-2">
              <label class="text-muted small mb-2">Enviar por:</label>
              <v-select
                v-model="selectedMethod"
                :options="sendMethods"
                :reduce="(item) => item.value"
                label="label"
                class="shadow-sm"
              ></v-select>
            </b-col>
          </b-row>

          <b-row v-if="selectedMethod">
            <b-col md="6" class="mb-2" v-if="selectedMethod === 'type'">
              <label class="text-muted small mb-2">Tipo de Usuario</label>
              <v-select
                v-model="selectedTypeUser"
                :options="rolesList"
                :reduce="(item) => item.name"
                label="name"
                class="shadow-sm"
              ></v-select>
            </b-col>

            <b-col md="6" class="mb-2" v-if="selectedMethod === 'user'">
              <label class="text-muted small mb-2">Usuarios</label>
              <v-select
                v-model="searchInput"
                :options="filteredUsers"
                :reduce="(item) => item.id"
                label="name"
                class="shadow-sm"
                :loading="loadingUsers"
                @search="handleSearch"
                @input="handleSelect"
                :filterable="false"
                :clearable="true"
                ref="userSelect"
                :open-on-focus="true"
              >
                <template #no-options>
                  Escriba para buscar usuarios...
                </template>
                <template #option="{ name, email }">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>{{ name }}</span>
                    <small class="text-muted">{{ email }}</small>
                  </div>
                </template>
              </v-select>

              <div class="mt-2" v-if="selectedUsers.length > 0">
                <b-table-simple hover responsive class="border user-table">
                  <b-tbody>
                    <b-tr v-for="user in selectedUsers" :key="user.id">
                      <b-td>
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span>{{ user.name }}</span>
                          <b-button
                            variant="outline-danger"
                            size="sm"
                            @click="removeUser(user)"
                            class="btn-icon"
                          >
                            <feather-icon icon="XIcon" size="12" />
                          </b-button>
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-col>
          </b-row>

          <div class="mb-3">
            <label class="text-muted small mb-2">Mensaje</label>
            <b-form-textarea
              id="textarea"
              v-model="msgBot"
              placeholder="Escribe el mensaje que se enviará a los usuarios seleccionados..."
              rows="4"
              class="shadow-sm"
            ></b-form-textarea>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <small class="text-muted">
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              {{ infoMessage }}
            </small>

            <b-button
              variant="primary"
              :disabled="!isFormValid"
              @click="sendMsg"
            >
              <feather-icon icon="SendIcon" size="18" class="mr-50" />
              Enviar
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BFormTextarea,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    BFormTextarea,
    vSelect,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
  },
  data() {
    return {
      isinfoBot: false,
      loading: false,
      loadingUsers: false,
      urlImageQr: process.env.VUE_APP_URL_API_BOT + "scan-qr",
      infoBot: {},
      intervalId: null,
      selectedMethod: null,
      filteredUsers: [],
      searchInput: null,
      selectedUsers: [],
      selectedTypeUser: null,
      msgBot: "",
      searchTimeout: null,
      sendMethods: [
        { label: "Tipo de Usuario", value: "type" },
        { label: "Usuarios", value: "user" },
      ],
      roleTranslations: {
        cashier: "Cajero",
        student: "Estudiante",
        teacher: "Profesor",
        representative: "Representante",
      },
    };
  },
  computed: {
    rolesList() {
      return this.$store.getters["user/roles"].map(role => ({
        ...role,
        name: this.roleTranslations[role.name] || role.name,
      }));
    },
    isFormValid() {
      if (!this.msgBot) return false;
      if (this.selectedMethod === "type") return !!this.selectedTypeUser;
      if (this.selectedMethod === "user") return this.selectedUsers.length > 0;
      return false;
    },
    infoMessage() {
      if (this.selectedMethod === "type") {
        return "El mensaje se enviará a todos los usuarios del tipo seleccionado";
      }
      return this.selectedUsers.length > 0
        ? `El mensaje se enviará a ${this.selectedUsers.length} usuario(s) seleccionado(s)`
        : "Seleccione los usuarios para enviar el mensaje";
    },
  },
  watch: {
    selectedMethod() {
      this.selectedTypeUser = null;
      this.selectedUsers = [];
      this.searchInput = null;
      this.filteredUsers = [];
    },
  },
  methods: {
    async configBot() {
      try {
        const configBot = await this.$store.dispatch(
          "botWhatsApp/getConfigBot"
        );
        this.infoBot = configBot?.infoBot || null;
      } catch (error) {
        console.error("Error configuring bot:", error);
      }
    },

    async handleSearch(search) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async () => {
        try {
          if (!search || search.length < 2) {
            this.filteredUsers = [];
            return;
          }

          this.loadingUsers = true;
          const response = await this.$store.dispatch("user/searchAllUser", {
            search: search,
          });

          this.filteredUsers = response.slice(0, 8).map((user) => ({
            id: user.id,
            name: `${user.name} ${user.last_name}`,
            email: user.email,
          }));
        } catch (error) {
          console.error("Error searching users:", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: "Error al buscar usuarios",
            },
          });
        } finally {
          this.loadingUsers = false;
        }
      }, 500);
    },

    handleSelect(selectedId) {
      if (!selectedId) return;

      const selectedUser = this.filteredUsers.find(
        (user) => user.id === selectedId
      );
      if (
        selectedUser &&
        !this.selectedUsers.some((user) => user.id === selectedId)
      ) {
        this.selectedUsers.push(selectedUser);

        this.$nextTick(() => {
          this.searchInput = null;
          if (this.$refs.userSelect) {
            this.$refs.userSelect.open = true;
            if (this.$refs.userSelect.$refs.search) {
              this.$refs.userSelect.$refs.search.focus();
            }
          }
        });
      }
    },
    removeUser(user) {
      this.selectedUsers = this.selectedUsers.filter((u) => u.id !== user.id);
    },

    async sendMsg() {
      try {
        if (!this.isFormValid) return;

        const data = {
          message: this.msgBot,
          userIds:
            this.selectedMethod === "user"
              ? this.selectedUsers.map((user) => user.id)
              : null,
          userType:
            this.selectedMethod === "type" ? this.selectedTypeUser : null,
        };

        await this.$store.dispatch("user/sendMsg", data);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Éxito",
            icon: "CheckIcon",
            variant: "success",
            text: "Mensaje enviado correctamente",
          },
        });

        this.resetForm();
      } catch (error) {
        console.error("Error sending message:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "Error al enviar el mensaje",
          },
        });
      }
    },

    resetForm() {
      this.msgBot = "";
      this.selectedUsers = [];
      this.selectedTypeUser = null;
      this.selectedMethod = null;
      this.searchInput = null;
      this.filteredUsers = [];
    },

    async fetchRoles() {
      try {
        await this.$store.dispatch("user/fetchRoles");
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
  },
  async mounted() {
    await this.configBot();
    await this.fetchRoles();
    this.intervalId = setInterval(() => {
      this.configBot();
    }, 5000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  transition: all 0.3s ease;
}

.form-control:focus,
.custom-select:focus {
  box-shadow: none !important;
  border-color: #80bdff;
}

.btn {
  transition: all 0.2s ease;
}

.btn:hover:not(:disabled) {
  transform: translateY(-1px);
}

.text-muted {
  font-size: 0.9rem;
}

.btn-icon {
  padding: 0.25rem;
  line-height: 1;
}

::v-deep .vs__dropdown-toggle {
  border-color: #d8d6de;
  padding: 4px;
}

::v-deep .vs__selected {
  margin: 0;
  padding: 0;
}

::v-deep .vs__search {
  padding: 0;
}

::v-deep .vs__dropdown-menu {
  padding: 5px;
  border-radius: 4px;
  border-color: #d8d6de;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.user-table {
  max-height: 200px; 
  overflow-y: auto;
  scroll-behavior: smooth;
}

.user-table::-webkit-scrollbar {
  width: 8px;
}

.user-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.user-table::-webkit-scrollbar-thumb {
  background: #9c9c9c; 
  border-radius: 4px;
  transition: background 0.2s ease;
}

.user-table::-webkit-scrollbar-thumb:hover {
  background: #7d7c7c;  
}

.user-table::-webkit-scrollbar-thumb:active {
  background: #626161;  
}
</style>